import React from 'react';
import './App.css';
import ToDoList from "./components/ToDo/ToDoList";

function App() {
  return (
    <div>
      <ToDoList/>
    </div>
  );
}

export default App;
